import { STOP_MIDDLEWARE } from './apiMiddleware'

/**
 * @class InterceptionError
 * @extends Error
 * @param {string} message Error message
 * @param {object} [cause = {}] Error instance | Object.
 * @returns {InterceptionError} InterceptionError instance
 *
 * @example
 *  const error = new InterceptionError('message');
 *  const error = new InterceptionError('message', { cause: Error });
 *  const error = new InterceptionError('message', { code: ERROR_CODE });
 */
export class InterceptionError extends Error {
  constructor(message, cause) {
    super(message)
    this.name = this.constructor.name
    this.code = STOP_MIDDLEWARE
    this.cause = cause
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('User is not logged in.')
    this.code = STOP_MIDDLEWARE
  }
}
