const PROD_RECAPTCHA_V2_SITE_KEY = '6LeL2PkfAAAAADvOsDAA_6m5KJLpV4fDdq6pvAUt'
// eslint-disable-next-line no-process-env
export const SITE_KEY = process.env.RECAPTCHA_V2_SITE_KEY || PROD_RECAPTCHA_V2_SITE_KEY

export const SAVE_SALE_CAPTCHA_KEY = 'api/oe/saved_sales'
export const SAVE_RENTAL_CAPTCHA_KEY = 'api/oe/saved_rentals'
export const SAVE_LAND_CAPTCHA_KEY = 'api/oe/saved_sales'
export const SIGN_IN_CAPTCHA_KEY = 'users/sessions'
export const SIGN_UP_CAPTCHA_KEY = 'users/registrations'
export const RESET_PASSWORD_CAPTCHA_KEY = 'users/passwords'
export const SHARE_LISTING_CAPTCHA_KEY = 'share_properties'
export const CONTACT_CAPTCHA_KEY = 'contacts'
export const SAVE_SEARCH_CAPTCHA_KEY = 'saved_searches'

const listingCaptchaKeys = {
  Sale: SAVE_SALE_CAPTCHA_KEY,
  Rental: SAVE_RENTAL_CAPTCHA_KEY,
  Land: SAVE_LAND_CAPTCHA_KEY
}

export const listingCaptchaKey = ({ listingClass }) => listingCaptchaKeys[listingClass]
