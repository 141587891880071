/* eslint no-console: 0 */

import load from 'load-script'
import { apiCall } from './api'
import { SITE_KEY } from '../config/recaptcha'
import { invokeAction } from '../globalActions'

const URL = 'https://www.google.com/recaptcha/api.js?onload=__recaptchaLoaded&render=explicit'

const errorCallback = error => {
  console.error('Captcha validate error: ', error)
}

const recaptchaLoaded = (captchaElement, callback) => () => {
  const recaptchaWidget = global.grecaptcha.render(captchaElement, {
    callback: gRecaptchaResponse => {
      global.grecaptcha.reset(recaptchaWidget)
      callback(gRecaptchaResponse)
    },
    errorCallback: error => {
      global.grecaptcha.reset(recaptchaWidget)
      errorCallback(error)
    },
    sitekey: SITE_KEY,
    size: 'invisible',
    badge: 'inline'
  })

  global.grecaptcha.execute(recaptchaWidget)
}

export const captchaWarningMessage = {
  type: 'error',
  text: `
    Sorry, but you're moving too fast. Please wait a minute and try again.
    If you think you are receiving this message in error, please contact support.
  `
}

export default ({ transport, message = null }) => ({ url, method, captchaKey, body = {} }) => new Promise(resolve => {
  const onCaptchaValid = gRecaptchaResponse => {
    const updatedBody = JSON.stringify({
      ...body,
      captcha: {
        gRecaptchaResponse
      }
    })

    resolve(apiCall(url, { method, body: updatedBody }))
  }

  transport.Captcha.show(captchaKey).then(resp => {
    const captchaElement = global.document.createElement('div')
    captchaElement.classList.add('grecaptcha-badge-holder')

    global.document.body.appendChild(captchaElement)

    if (resp.success) {
      if (global.grecaptcha) {
        recaptchaLoaded(captchaElement, onCaptchaValid)()
      } else {
        global.__recaptchaLoaded = recaptchaLoaded(captchaElement, onCaptchaValid)
        load(URL)
      }

      if (message) {
        invokeAction('FlashMessages', 'addFlashMessage', message)
      }
    } else {
      const updatedBody = JSON.stringify({
        ...body,
        captcha: {
          gRecaptchaResponse: ''
        }
      })
      resolve(apiCall(url, { method, body: updatedBody}))
    }
  })
})
