/* eslint camelcase: 0 */
/* eslint no-use-before-define: 0 */

import { lens } from 'lorgnette'
import { saveToSessionStorage, fetchFromSessionStorage } from '../utils/browserStorage'
import { isDefined, isError } from '../utils/common'

const CALL_LIMIT = 5
const PENDING_SERVICE_CALLS_KEY = 'PENDING_SERVICE_CALLS'
const SERVICE_CALLS_JOURNAL_KEY = 'SERVICE_CALLS_JOURNAL'

let queue
let journal

const init = () => {
  global.__pendingServiceCalls_queue = global.__pendingServiceCalls_queue || []
  global.__pendingServiceCalls_journal = global.__pendingServiceCalls_journal || { callCounts: {} }

  queue = global.__pendingServiceCalls_queue
  journal = global.__pendingServiceCalls_journal
}
init()

const serviceIdentifier = ({name, args}) => {
  return `${name.join('_')}_${JSON.stringify(args)}`
}

const incrementCallCount = serviceCall => {
  const id = serviceIdentifier(serviceCall)
  const callCount = journal.callCounts[id]

  if (isDefined(callCount)) {
    journal.callCounts[id] = callCount + 1
  } else {
    journal.callCounts[id] = 1
  }
}

const serialize = () => {
  saveToSessionStorage(PENDING_SERVICE_CALLS_KEY, queue)
  saveToSessionStorage(SERVICE_CALLS_JOURNAL_KEY, journal)
}

const deserialize = () => {
  const queueFromSession = fetchFromSessionStorage(PENDING_SERVICE_CALLS_KEY)
  const journalFromSession = fetchFromSessionStorage(SERVICE_CALLS_JOURNAL_KEY)

  if (queueFromSession) {
    queueFromSession.forEach(q => {
      queue.push(q)
    })
  }

  if (journalFromSession) {
    Object.keys(journalFromSession.callCounts).forEach(k => {
      journal.callCounts[k] = journalFromSession.callCounts[k]
    })
  }
}

const resetCallCount = serviceCall => {
  delete journal.callCounts[serviceIdentifier(serviceCall)]
}

const notify = (serviceCall, data) => {
  const eventName = serviceCall.name.join('.')
  const event = new CustomEvent(eventName, { detail: { data }})

  global.dispatchEvent(event)
}

const isEnqueued = serviceCall => {
  return queue.some(s => serviceIdentifier(s) === serviceIdentifier(serviceCall))
}

const processService = (serviceCall, services) => {
  const { name, args } = serviceCall
  const serviceLens = name.reduce((l, namePart) => l.prop(namePart), lens)

  serviceLens.get(services).then(s => {
    s.call(null, args).then(r => {
      if (!isError(r)) {
        notify(serviceCall, r)
        resetCallCount(serviceCall)
        serialize()
      }
    })
  })
}

export const cleanupQueue = () => {
  while (queue.length) {
    queue.pop()
  }
  serialize()
}

export const addServiceCall = serviceCall => {
  if (isEnqueued(serviceCall)) {
    return
  }

  incrementCallCount(serviceCall)

  if (journal.callCounts[serviceIdentifier(serviceCall)] < CALL_LIMIT) {
    queue.push(serviceCall)
  } else {
    resetCallCount(serviceCall)
  }

  serialize()
}

export const start = services => {
  deserialize()

  queue.forEach(q => {
    processService(q, services)
  })

  cleanupQueue()
}
