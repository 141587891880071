import { addParams } from '../utils/common'
import { getCSRFtoken } from './dom'
import {
  withMiddleware,
  catchCsrfTokenErrorAndCall,
  csrfTokenFailure,
  parseJson
} from './apiMiddleware'

export const apiCall = (path, options) => (
  fetch(path, {
    ...options,
    credentials: 'include',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
      'X-CSRF-Token': getCSRFtoken()
    }
  })
)

export const externalApiCall = (path, options) => (
  fetch(path, {
    ...options,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    }
  })
)

export const responseAsJson = withMiddleware(catchCsrfTokenErrorAndCall(csrfTokenFailure), parseJson)(apiCall)

export const post = (path, data) => responseAsJson(path, {
  method: 'POST',
  body: JSON.stringify(data)
})

export const get = path => responseAsJson(addParams(path, { format: 'json' }), {
  method: 'GET'
})

export const externalGet = path => withMiddleware(parseJson)(externalApiCall)(path, { method: 'GET' })

export const del = (path, data) => responseAsJson(path, {
  method: 'DELETE',
  body: JSON.stringify(data)
})

export const put = (path, data) => responseAsJson(path, {
  method: 'PUT',
  body: JSON.stringify(data)
})

export const upload = (path, formData) => {
  const data = new FormData()

  Object.keys(formData).forEach(name => {
    data.append(name, formData[name])
  })

  return fetch(path, {
    method: 'POST',
    credentials: 'include',
    body: data,
    headers: {
      'X-CSRF-Token': getCSRFtoken()
    }
  })
    .then(r => r.json())
}

export const externalUpload = (url, file, method = 'PUT', headers = {}) => {
  return fetch(url, {
    headers: headers,
    method: method,
    body: file
  }).then(response => {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json()
    }

    return response
  })
}
