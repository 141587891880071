/* eslint no-console: 0 */
/* eslint no-plusplus: 0 */

export const noop = () => {}

export const tap = (v, m = '') => {
  console.log(m, v)
  return v
}
global.tap = tap

export const log = console.log
global.log = log

export const isDefined = v => typeof v !== 'undefined'

export const isNull = v => v === null

export const isServer = () => (
  typeof window === 'undefined'
)

export const debounce = (func, wait, immediate) => {
  let timeout

  function f(...args) {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, args)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    f.timeout = timeout

    if (callNow) {
      func.apply(this, args)
    }
  }

  return f
}

export const debouncePromise = (inner, ms = 0) => {
  let timer = null
  let resolves = []

  function f(...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      const result = inner(...args)
      resolves.forEach(r => r(result))
      resolves = []
    }, ms)

    return new Promise(r => resolves.push(r))
  }

  return f
}

const memoizedFuncs = []
export const memoize = func => {
  let memo = {}

  const memoized = (...args) => {
    if (args in memo) {
      return memo[args]
    }

    return (memo[args] = func.apply(this, args))
  }

  memoized.__clear = () => {
    memo = {}
  }

  memoizedFuncs.push(memoized)

  return memoized
}

export const clearCache = func => {
  if (func) {
    memoizedFuncs.forEach(f => {
      if (f === func) {
        func.__clear()
      }
    })
  }
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const isEmptyObject = o => Object.keys(o).length === 0

export const redirect = url => {
  global.location.href = url
}

export const isError = response => (
  (isDefined(response.ok) && !response.ok) ||
    response.error ||
    response.errors
)

export const getResponseErrors = response => response && (response.error || response.errors)

export const reload = () => {
  global.location.reload()
}

export const open = url => {
  global.open(url)
}

export const isInt = n => Number(n) === n && n % 1 === 0
export const isArray = v => Object.prototype.toString.call(v) === '[object Array]'
export const isNumeric = n => (
  !isArray(n)
    && !isNaN(parseFloat(n))
    && isFinite(n)
    && !(/^0\d+$/.test(n))
    && Number(n) < Number.MAX_SAFE_INTEGER
)
export const isObject = v => v !== null && !isArray(v) && typeof v === 'object'

export const serialize = (obj, prefix) => {
  const query = Object.keys(obj).map(key => {
    let keyParam = key
    const value = obj[keyParam]

    if (isArray(obj)) {
      keyParam = `${prefix}[]`
    } else if(isObject(obj)) {
      keyParam = (prefix ? `${prefix}[${keyParam}]` : keyParam)
    }

    if(typeof value === 'object' && value !== null) {
      return serialize(value, keyParam)
    }

    return `${keyParam}=${encodeURIComponent(value)}`
  })

  return query.join('&')
}

export const throttle = (func, ms) => {
  let isThrottled = false
  let savedArgs

  const wrapper = function () {
    if (isThrottled) {
      savedArgs = arguments
      return
    }

    func.apply(this, arguments)

    isThrottled = true

    setTimeout(() => {
      isThrottled = false
      if (savedArgs) {
        wrapper.apply(this, savedArgs)
        savedArgs = null
      }
    }, ms)
  }

  return wrapper
}

export const getParam = name => {
  const windowLocationHref = global.location.href
  const url = new URL(windowLocationHref)
  return url.searchParams.get(name)
}

export const addParams = (pathname, params = {}) => {
  const paramsRegex = /[?&]([^=#]+)=([^&#]*)/g
  const pathRegex = /(.*)\?/
  const searchEntries = Object.entries(params).map(e => e.join('='))

  let match

  // eslint-disable-next-line no-cond-assign
  while (match = paramsRegex.exec(pathname)) {
    searchEntries.push(`${match[1]}=${match[2]}`)
  }

  const searchString = searchEntries.join('&')
  const pathMatch = pathname.match(pathRegex)
  const path = pathMatch ? pathMatch[1] : pathname

  return `${path}?${searchString}`
}

export const fixedEncodeURIComponent = str => (
  encodeURIComponent(str).replace(/[!'()*]/g, c => (
    '%' + c.charCodeAt(0).toString(16)
  ))
)

export const stripHTMLTags = str => (str.replace(/(<([^>]+)>)/ig, ''))

export const makeId = () => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let text = ''

  for (let i = 0; i < 5; i = i + 1) {
    text = text + possible.charAt(
      Math.floor(Math.random() * possible.length)
    )
  }

  return text
}

export const inRange = (n, { from, to }) => (
  Math.max(Math.min(n, to), from)
)

export const matchesObject = (pattern, obj) => (
  Object.keys(pattern).every(k => obj[k] === pattern[k])
)

export const makeCancelable = promise => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
      error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    }
  }
}

export const shuffleArray = array => [...array].sort(() => Math.random() - 0.5)

export const humanFileSize = (bytes, si = true) => {
  const thresh = si ? 1000 : 1024
  let b = bytes

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  let u = -1

  do {
    b = b / thresh
    ++u
  } while (Math.abs(b) >= thresh && u < units.length - 1)

  return b.toFixed(1) + ' ' + units[u]
}

export const isEmail = str => (
  str && str.includes && str.includes('@')
)

export const isString = str => typeof str === 'string'

export function callThrottler(limit) {
  let count = 0
  return function (callback) {
    if (count === limit || count === 0) {
      callback()
      count = 0
    }
    count = count + 1
  }
}
