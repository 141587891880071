import localStorage from './localStorage'

export const CONTACT_EMAIL = 'userInfo_contactEmail'
export const CONTACT_NAME = 'userInfo_contactName'
export const SHARE_EMAIL = 'userInfo_shareEmail'
export const PHONE_NUMBER = 'userInfo_phoneNumber'
export const SHARE_RECIPIENT_EMAIL = 'userInfo_shareRecipientEmail'
export const SIGNIN_EMAIL = 'userInfo_signInEmail'
export const USER_ID = 'userInfo_userId'

export default {
  getInfo(name) {
    return localStorage.fetchFromLocalStorage(name)
  },
  setInfo(name, value) {
    localStorage.saveToLocalStorage(name, value)
    return this
  },
  clear() {
    Array.of(CONTACT_EMAIL, CONTACT_NAME, SHARE_EMAIL, PHONE_NUMBER, SHARE_RECIPIENT_EMAIL, SIGNIN_EMAIL, USER_ID)
      .forEach(key => localStorage.removeFromLocalStorage(key))
  }
}
