const pushState = ({ state, url }) => {
  global.history.pushState(state, null, url)
}

const replaceState = ({ state, url }) => {
  global.history.replaceState(state, null, url)
}

export default {
  pushState,
  replaceState
}
