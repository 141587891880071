import { invokeAction } from '../../lib/globalActions'

export default {
  show: message => new Promise(resolve => {
    global.addEventListener('toast-flash-messages:loaded', () => {
      invokeAction('FlashMessages', 'addFlashMessage', message)
      resolve({})
    })
  })
}
