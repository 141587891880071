/* eslint no-constant-condition: 0 */

import { chan, go, take, putAsync } from 'js-csp'

const channels = {}
const callbacks = {}

const initChan = name => {
  if (!channels[name]) {
    channels[name] = chan()
    callbacks[name] = []
  }
}

const putChan = (name, value) => {
  putAsync(channels[name], value)
}

const takeChan = (name, callback) => {
  callbacks[name].push(callback)

  go(function *() {
    while (true) {
      let value = yield take(channels[name])
      callbacks[name].forEach(cb => { cb(value) })
    }
  })
}

export default {
  init: initChan,
  put: putChan,
  take: takeChan
}
