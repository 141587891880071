/* eslint no-plusplus: 0 */
/* eslint no-empty: 0 */
/* eslint no-undefined: 0 */
/* eslint no-loop-func: 0 */
/* eslint no-use-before-define: 0 */

import React from 'react'
import FontFaceObserver from 'fontfaceobserver'
import { isDefined, open, redirect, isServer, noop } from './common'

export const placeCaretAtEnd = el => {
  if (isDefined(global.getSelection) && isDefined(document.createRange)) {
    const range = document.createRange()
    const sel = global.getSelection()
    range.selectNodeContents(el)
    range.collapse(false)
    sel.removeAllRanges()
    sel.addRange(range)
  } else if (isDefined(document.body.createTextRange)) {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}

export const getCSRFtoken = () => {
  const m = document.querySelector('meta[name="csrf-token"]')
  return m ? m.content : ''
}

export const fromContentEditable = str => str.replace(/\s/, ' ')

const calculateEllipsis = (element, height, cb) => {
  const clone = element.cloneNode(true)
  let elementsToHide = 0

  clone.style.position = 'absolute'
  clone.style.visibility = 'hidden'
  clone.style.width = element.clientWidth + 'px'

  document.body.appendChild(clone)

  Array.from(clone.children).forEach(child => {
    child.style.display = 'block'
  })

  if (element.childElementCount === 0) {
    clone.remove()
    cb(0)
  } else {
    for (let s = element.childElementCount - 1; s >= 0; s--) {
      global.requestAnimationFrame(() => {
        if (clone.clientHeight > height) {
          clone.children[s].remove()
          elementsToHide++
        }

        if (s === 0) {
          clone.remove()
          cb(elementsToHide)
        }
      })
    }
  }
}

export const fontFaceObserver = (el, { fontFamily } = {}) => {
  let fontFamilyName
  let observer

  if (fontFamily) {
    fontFamilyName = fontFamily
  } else if (el) {
    fontFamilyName = global.getComputedStyle(el, null)
      .getPropertyValue('font-family')
      .replace(/['"]+/g, '')
  }

  if (fontFamilyName) {
    observer = (new FontFaceObserver(fontFamilyName)).load(null, 15000)
  } else {
    observer = Promise.resolve()
  }

  return observer
}

export const pillEllipsis = (element, height, cb) => {
  fontFaceObserver(element, { fontFamily: 'P22Underground' })
    .then(() => calculateEllipsis(element, height, cb))
}

export const globalScrollPosition = () => {
  const supportPageOffset = global.pageXOffset !== undefined
  const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat')

  let x
  let y

  if (supportPageOffset) {
    x = global.pageXOffset
    y = global.pageYOffset
  } else if (isCSS1Compat) {
    x = document.documentElement.scrollLeft
    y = document.documentElement.scrollTop
  } else {
    x = document.body.scrollLeft
    y = document.body.scrollTop
  }

  return { x, y }
}

export const offset = elt => {
  const display = elt.style.display
  elt.style.display = 'initial'
  const rect = elt.getBoundingClientRect()
  const top = rect.top
  const left = rect.left
  elt.style.display = display

  const scrollX = globalScrollPosition().x
  const scrollY = globalScrollPosition().y

  return {
    top: top + scrollY,
    left: left + scrollX
  }
}

export const offsetTop = el => (el.getBoundingClientRect().top + globalScrollPosition().y)

export const outerHeight = el => {
  const styles = global.getComputedStyle(el)
  const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom)

  return Math.ceil(el.offsetHeight + margin)
}

export const isScrolledIntoView = (elem, container, partial = false) => {
  const contHeight = container.offsetHeight

  const elemTop = offset(elem).top - offset(container).top
  const elemBottom = elemTop + elem.offsetHeight

  const isTotal = elemTop >= 0 && elemBottom <= contHeight
  const isPart = ((elemTop < 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= container.offsetHeight)) && partial

  return isTotal || isPart
}

export const isScrolledIntoGlobalView = el => {
  const rect = el.getBoundingClientRect()
  const elemTop = rect.top
  const elemBottom = rect.bottom

  return (elemTop >= 0) && (elemBottom <= window.innerHeight)
}

export const scrollIntoView = (elem, container) => {
  const goDown = offset(elem).top > container.height

  if (!isScrolledIntoView(elem, container)) {
    container.scrollTop = goDown ? container.scrollTop - container.height + elem.clientHeight : elem.offsetTop
  }
}

export const smoothScrollTo = (position, node) => {
  if (node) {
    node.scroll({ top: position, left: 0, behavior: 'smooth' })
  }
  global.scroll({ top: position, left: 0, behavior: 'smooth' })
}

export const isHidden = node => {
  const style = global.getComputedStyle(node)
  return (style.display === 'none')
}

export const calculateTextEllipsis = element => {
  const text = element.innerHTML
  const clone = element.cloneNode(true)
  const computedStyle = global.getComputedStyle(element, null)

  // Cleanup height styles to allow div growth
  clone.style.height = 'auto'
  clone.style.maxHeight = 'none'

  // Hide element somewhere on the page
  clone.style.position = 'absolute'
  clone.style.visibility = 'hidden'
  clone.style.width = element.clientWidth + 'px'

  // Copy some essential styles
  clone.style.fontSize = computedStyle.getPropertyValue('font-size')
  clone.style.lineHeight = computedStyle.getPropertyValue('line-height')

  document.body.appendChild(clone)

  for (let l = text.length - 1; l >= 0 && clone.clientHeight > element.clientHeight; l--) {
    clone.innerHTML = text.substring(0, l) + '...'
  }

  const result = clone.innerHTML
  clone.remove()

  return result
}

export const textEllipsis = element => {
  element.innerHTML = calculateTextEllipsis(element)
}

export const actsAsForceOpenClick = url => e => {
  if (e.target.tagName.toUpperCase() === 'A') {
    return false
  }

  e.stopPropagation()
  e.preventDefault()

  open(url)
  return false
}

export const actsAsLinkClick = url => e => {
  if (e.target.tagName.toUpperCase() === 'A') {
    return false
  }

  e.stopPropagation()
  e.preventDefault()

  if (e.metaKey || e.ctrlKey) {
    open(url)
    return false
  }

  redirect(url)
  return false
}

export const actsAsLinkMiddleClick = url => e => {
  if (e.target.tagName.toUpperCase() === 'A') {
    return false
  }

  e.stopPropagation()
  e.preventDefault()

  // Middle button check for native and react events
  if (e.which === 2 || e.button === 1) {
    open(url)
  }

  return false
}

export const onKey = (e, name, callback) => {
  if (e.key === name) {
    e.stopPropagation()
    e.preventDefault()
    callback(e)
  }
}

export const watchKey = (e, name, callback) => {
  if (e.key === name) {
    callback(e)
  }
}

export const replaceImage = imageUrl => e => {
  e.target.onerror = null
  e.target.src = imageUrl
}

export const getPositions = elem => {
  const pos = offset(elem)
  const width = elem.offsetWidth
  const height = elem.offsetHeight

  return [[pos.left, pos.left + width], [pos.top, pos.top + height]]
}

export const comparePositions = (p1, p2) => {
  const r1 = p1[0] < p2[0] ? p1 : p2
  const r2 = p1[0] < p2[0] ? p2 : p1

  return r1[1] > r2[0] || r1[0] === r2[0]
}

export const hasOverlap = ([a, b]) => {
  const pos1 = getPositions(a)
  const pos2 = getPositions(b)

  return comparePositions(pos1[0], pos2[0]) && comparePositions(pos1[1], pos2[1])
}

export const isFieldWithKeyboard = () => {
  const keyboardFieldTypes = ['text', 'password', 'search', 'email', 'tel']
  const activeElement = global.document.activeElement

  return !activeElement.dataset.withoutHideOnMobileKeyboard &&
    (keyboardFieldTypes.includes(activeElement.type) ||
      activeElement.nodeName === 'TEXTAREA' ||
      activeElement.contentEditable === 'true')
}

export const keyboradDisplayEvent = (onShow, onHide) => {
  const onBlur = () => {
    global.removeEventListener('touchend', onTouchLeave)
    onHide()
  }

  const onTouchLeave = () => {
    setTimeout(() => {
      if (!isFieldWithKeyboard()) {
        onBlur()
      }
    }, 200)
  }

  const onFocus = e => {
    setTimeout(() => {
      global.document.addEventListener('touchend', onTouchLeave)
      onShow(e)
    }, 200)
  }

  return onFocus
}

export const hasParent = (node, parent) => {
  const els = []
  let a = node

  while (a) {
    els.unshift(a)
    a = a.parentNode
  }

  return els.includes(parent)
}

export const isOutOfRect = (element, children) => {
  const elementRect = element.getBoundingClientRect()
  const allCoordinates = Array.from(children).map(child => Math.round(child.getBoundingClientRect().left))

  const hasLeftElements = !!allCoordinates.find(c => c < elementRect.left)
  const hasRightElements = !!allCoordinates.find(c => c > elementRect.right)

  return { hasLeftElements, hasRightElements }
}

export const findAncestor = (el, sel) => {
  let ancestor = el

  while ((ancestor = ancestor.parentElement) && !((ancestor.matches || ancestor.matchesSelector
    || ancestor.msMatchesSelector).call(ancestor, sel))) {
  }

  return ancestor
}

export const getClipboardText = e => (
  e.clipboardData.getData('text/plain')
)

export const onClickWithoutSelection = (() => {
  let clickTime = 0
  const pos = { x: 0, y: 0 }

  return callback => ({
    onMouseDown: ({ nativeEvent: e }) => {
      clickTime = Date.now()
      pos.x = e.x
      pos.y = e.y
    },
    onMouseUp: ({ nativeEvent: e }) => {
      if (e.target.dataset.propagation === 'stop') {
        return
      }
      if (Date.now() - clickTime < 200 && pos.x === e.x && pos.y === e.y) {
        callback()
      }
    }
  })
})()

const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement('textarea')

  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  return new Promise((resolve, reject) => {
    try {
      document.execCommand('copy')
      resolve()
    } catch (err) {
      reject(err)
    }

    document.body.removeChild(textArea)
  })
}

export const copyTextToClipboard = text => {
  if (!global.navigator.clipboard) {
    return fallbackCopyTextToClipboard(text)
  }

  return navigator.clipboard.writeText(text)
}

export const isTouchDevice = isServer()
  ? false
  : 'ontouchstart' in global || navigator.maxTouchPoints || navigator.msMaxTouchPoints

export const eventBus = isServer() ? { addEventListener: noop } : global

// See https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
if (isServer()) {
  React.useLayoutEffect = noop
}
