/* eslint no-console: 0 */

import { isNumeric, redirect, isError, matchesObject } from './common'
import { isRegistered, invokeAction } from '../globalActions'
import { isEmpty } from './collection'
import { addServiceCall } from '../services/pendingServiceCalls'
import { InterceptionError, UnauthorizedError } from './errors'

export const STOP_MIDDLEWARE = 'STOP_MIDDLEWARE'

export const stopMiddleware = error => {
  if (error) { throw error }

  throw new InterceptionError()
}

export const withMiddleware = (...middlewares) => wrapped => (...wrappedArgs) => (
  middlewares.reduce(
    (res, middleware) => (
      middleware.call(null, res)
    ), wrapped.apply(null, wrappedArgs)
  ).catch(error => {
    // Propagate error to the caller
    if (error.code === STOP_MIDDLEWARE) {
      throw error
    }
    console.error(error)
  })
)

export const catchUnauthorizedAndCall = callback => response => (
  response.then(r => {
    if (r.status === 401) {
      callback.call(null, r)
    }
    return r
  })
)

export const catchCsrfTokenErrorAndCall = callback => response => (
  response.then(r => {
    if (r.status === 422 && r.headers.map['x-csrf-error']) {
      return callback.call(null, r)
    }
    return r
  })
)

export const parseJson = response => (
  response
    .then(r => r.text())
    .then(r => JSON.parse(r, (k, v) => isNumeric(v) ? parseFloat(v) : v))
    .catch(() => {
      return response.then(r => {
        const message = `Something went wrong. ${r.statusText} (${r.status}).`
        const error = new InterceptionError(message)
        stopMiddleware(error)
      })
    })
)

export const withResponseAndData = response => (
  Promise.all([response, parseJson(response)])
)

export const withRedirectURL = next => (
  next.then(([response, data]) => (
    isEmpty(response.headers.map.location)
      ? data
      : { ...data, redirectURL: response.headers.map.location[0] }
  ))
)

export const authenticationFailure = redirectPath => () => {
  if (isRegistered('AuthModal', 'openModal')) {
    invokeAction('AuthModal', 'openModal')
    stopMiddleware(new UnauthorizedError())
  } else {
    redirect(redirectPath)
  }
}

export const csrfTokenFailure = response => (
  response.json().then(responseJson => {
    invokeAction(
      'FlashMessages', 'addFlashMessage',
      { type: 'error', text: responseJson.message }
    )
    stopMiddleware()
  })
)

export const withPendingServiceCallOnError = (serviceCall, errorPattern = null) => next => {
  return next.then(response => {
    if (isError(response)) {
      if (errorPattern) {
        if (matchesObject(errorPattern, response)) {
          addServiceCall(serviceCall)
        }
      } else {
        addServiceCall(serviceCall)
      }
    }

    return response
  })
}

export const withNotify = ({ name }) => next => {
  return next.then(data => {
    if (!isError(data)) {
      const eventName = name.join('.')
      const event = new CustomEvent(eventName, { detail: { data } })

      global.dispatchEvent(event)
    }

    return data
  })
}
