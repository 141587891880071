/* eslint no-undefined: 0 */
/* eslint no-shadow: 0 */

import { camelizeKeys, decamelizeKeys } from 'humps'
import { lens, multi } from 'lorgnette'
import { isObject, isArray, isDefined, isNumeric, isNull } from './common'
import { isObservableArray } from 'mobx'
import * as R from 'ramda'

export const isEmpty = v => (
  v === null ||
    v === undefined ||
    (isDefined(v.length) && v.length === 0) ||
    (v.constructor === Object && Object.keys(v).length === 0)
)

export const isPresent = v => !isEmpty(v)

export const removeByIndex = (arr, index) => (
  [...arr.slice(0, index), ...arr.slice(index + 1)]
)

export const removeFromArray = (arr, value) => {
  const index = arr.indexOf(value)

  if (index !== -1) {
    return removeByIndex(arr, index)
  }

  return arr
}

export const uniqBy = (a, key) => {
  const seen = {}
  return a.filter(item => {
    const k = key(item)
    return seen.hasOwnProperty(k) ? false : (seen[k] = true)
  })
}

export const flatten = arr => (
  arr.reduce(
    (flat, toFlatten) => flat.concat(
      Array.isArray(toFlatten) || isObservableArray(toFlatten) ? flatten(toFlatten) : toFlatten
    ),
    []
  )
)

export const dfs = (obj, filterFn) => {
  const paths = []

  const dfsIter = (path, obj, filterFn) => {
    const keyType = isArray(obj) ? 'at' : 'prop'

    Object.keys(obj).forEach(k => {
      const v = obj[k]

      if (isObject(v) || isArray(v)) {
        dfsIter([...path, { value: k, keyType }], v, filterFn)
      } else if (filterFn(v)) {
        paths.push([...path, { value: k, keyType }])
      }
    })

    return paths
  }

  return dfsIter([], obj, filterFn)
}

export const updateIn = (obj, filterFn, updateFn) => {
  const paths = dfs(obj, filterFn)
  const lenses = paths.map(path => path.reduce((l, {keyType, value}) => (
    l[keyType](keyType === 'at' ? parseInt(value, 10) : value)
  ), lens))
  const multiLens = multi(...lenses)
  const updateFns = Array.from({length: lenses.length}).map(() => updateFn)

  return multiLens.update(obj, ...updateFns)
}

export const convertNumeric = obj => updateIn(obj, isNumeric, v => parseFloat(v))
export const replaceNullWithUndefined = obj => updateIn(obj, isNull, () => undefined)

export const fromServerToClient = data => convertNumeric(camelizeKeys(data))
export const fromClientToServer = data => decamelizeKeys(data)
export const errorsFromServerToClient = errors => (
  Object.keys(errors).reduce((acc, name) => {
    const error = errors[name]

    if (isArray(error) && isArray(error[0]) && isObject(error[0][0])) {
      const innerErrors = error[0]
      innerErrors.forEach((innerError, index) => {
        Object.keys(innerError).forEach(innerName => {
          acc[`${name}.${index}.${innerName}`] = innerError[innerName]
        })
      })
    } else {
      acc[name] = errors[name]
    }

    return acc
  }, {})
)

export const intersection = (arr1, arr2) => {
  return arr1.filter(n => arr2.includes(n))
}

export const lensFor = path => (
  path.split('.').reduce((l, pathSection) => (
    isNumeric(pathSection) ? l.at(Number(pathSection)) : l.prop(pathSection, {})
  ), lens)
)

export const isSuperset = (set, subset) => {
  for (let elem of subset) {
    if (!set.has(elem)) {
      return false
    }
  }
  return true
}

export const setIntersection = (setA, setB) => {
  const result = new Set()
  for (let elem of setB) {
    if (setA.has(elem)) {
      result.add(elem)
    }
  }
  return result
}


export const isLast = (i, arr) => (
  arr.length === i + 1
)

export const separate = R.curry((n, list) => {
  const len = list.length
  const f = (_v, idx) => Math.floor(idx * n / len)
  return R.values(R.addIndex(R.groupBy)(f, list))
})

export const withEmptyValue = value => arr => [value, ...arr]

export const reorder = (list, startIndex, endIndex) => {
  if (isPresent(list)) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  return list
}

export const cyclicIterator = array => {
  let index = 0
  const length = array.length

  return {
    getCurrent: function () {
      return array[index]
    },

    getNext: function () {
      index = R.modulo(R.inc(index), length)
      return this.getCurrent()
    },

    getPrevious: function () {
      index = R.mathMod(R.dec(index), length)
      return this.getCurrent()
    }
  }
}

export const iterator = (array, currentIndex = 0) => {
  let index = currentIndex
  const length = array.length

  return {
    getNext: function () {
      if (index < length - 1) {
        index = index + 1
      }
      return array[index]
    },

    getPrevious: function () {
      if (index > 0) {
        index = index - 1
      }
      return array[index]
    }
  }
}

export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj))
)

export const sanitizedParams = params => R.filter(v => isPresent(v), params)
