/* eslint camelcase: 0 */

global.__global_actions__ = {}

export const registerActions = props => (registerName, ...actionNames) => {
  global.__global_actions__[registerName] = actionNames.reduce((acc, name) => (
    {...acc, [name]: props[name]}
  ), global.__global_actions__[registerName] || {})
}

export const getAction = (registerName, actionName) => global.__global_actions__[registerName][actionName]

export const isRegistered = (registerName, actionName) => {
  return !!global.__global_actions__[registerName] && !!getAction(registerName, actionName)
}

export const invokeAction = (registerName, actionName, params) => {
  return isRegistered(registerName, actionName) && getAction(registerName, actionName)(params)
}
