const isSessionStorageAvailable = () => {
  const testKey = 'test'

  try {
    global.sessionStorage.setItem(testKey, '1')
    global.sessionStorage.removeItem(testKey)
    return true
  } catch (error) {
    return false
  }
}

const isLocalStorageAvailable = () => {
  const testKey = 'test'

  try {
    global.localStorage.setItem(testKey, '1')
    global.localStorage.removeItem(testKey)
    return true
  } catch (error) {
    return false
  }
}

export const saveToSessionStorage = (key, value) => {
  if (isSessionStorageAvailable()) {
    global.sessionStorage.setItem(key, JSON.stringify(value))
  }
}

export const fetchFromSessionStorage = key => {
  if (isSessionStorageAvailable()) {
    return JSON.parse(global.sessionStorage.getItem(key))
  }

  return null
}

export const removeFromSessionStorage = key => {
  if (isSessionStorageAvailable()) {
    global.sessionStorage.removeItem(key)
  }
}

export const saveToLocalStorage = (key, value) => {
  if (isLocalStorageAvailable()) {
    global.localStorage.setItem(key, JSON.stringify(value))
  }
}

export const fetchFromLocalStorage = key => {
  if (isLocalStorageAvailable()) {
    return JSON.parse(global.localStorage.getItem(key))
  }

  return null
}

export const removeFromLocalStorage = key => {
  if (isLocalStorageAvailable()) {
    global.localStorage.removeItem(key)
  }
}
