/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */
/* eslint no-console: 0 */

// event_action 'contact' :app/views/listors/_listor_profile_partial.html.haml

import * as yup from 'yup'
import { analytics } from './index'

const ANALYTICS_TIMEOUT = 1000

const validate = ({ params, schema }) => {
  try {
    schema.validateSync(params, { abortEarly: false })
  }
  catch(validationErrors) {
    console.error(validationErrors)
  }
}

const requiredString = key => ({ [key]: yup.string().required() })

const Hdp = {
  breadcrumbs: callback => {
    analytics.pushEvent('custom_event', {
      event_category: 'navigation',
      event_action: 'hdp_breadcrumbs',
      event_label: 'hdp_breadcrumbs',
      event_value: undefined,
      event_non_interaction: false,
      eventCallback: callback,
      eventTimeout: ANALYTICS_TIMEOUT
    })
    setTimeout(callback, ANALYTICS_TIMEOUT + 200)
  },

  addToCart: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'agent',
      event_action: 'hdp_add_to_cart',
      event_label: 'hdp_add_to_cart',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  listingsNavigation: ({ eventLabel, callback }) => {
    validate({
      params: { eventLabel },
      schema: yup.object({ ...requiredString('eventLabel') })
    })

    analytics.pushEvent('custom_event', {
      event_category: 'navigation',
      event_action: 'hdp_search_results_visit',
      event_label: eventLabel,
      event_value: undefined,
      event_non_interaction: false,
      eventCallback: callback,
      eventTimeout: ANALYTICS_TIMEOUT
    })
    setTimeout(callback, ANALYTICS_TIMEOUT + 200)
  },

  openListingCarousel: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'agent',
      event_action: 'hdp_listing_carousel',
      event_label: 'interact_open_listing_carousel',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  saveListing: eventLabel => {
    validate({
      params: { eventLabel },
      schema: yup.object({ ...requiredString('eventLabel') })
    })

    analytics.pushEvent('custom_event', {
      event_category: 'listing',
      event_action: 'save_item',
      event_label: eventLabel,
      event_value: undefined,
      event_non_interaction: false
    })
  },

  showAgentNumber: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'agent',
      event_action: 'contact',
      event_label: 'click_to_show_phone_number_on_hdp',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  showSalePriceHistory: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'sale_listing',
      event_action: 'view_price_history',
      event_label: 'view price history',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  openListing: () => {
    analytics.pushEvent('virtual_pageview')
  },

  shareFormOpen: eventCategory => {
    validate({
      params: { eventCategory },
      schema: yup.object({ ...requiredString('eventCategory') })
    })

    analytics.pushEvent('custom_event', {
      event_category: eventCategory,
      event_action: 'share',
      event_label: 'open',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  shareSubmit: eventCategory => {
    validate({
      params: { eventCategory },
      schema: yup.object({ ...requiredString('eventCategory') })
    })

    analytics.pushEvent('custom_event', {
      event_category: eventCategory,
      event_action: 'share',
      event_label: 'send',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  contactFormOpen: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'listing',
      event_action: 'contact',
      event_label: 'open',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  photoGalleryMapOpen: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'listing',
      event_action: 'map',
      event_label: 'open',
      event_value: undefined,
      event_non_interaction: false
    })
  }
}

const Srp = {
  breadcrumbs: callback => {
    analytics.pushEvent('custom_event', {
      event_category: 'navigation',
      event_action: 'srp_breadcrumbs',
      event_label: 'srp_breadcrumbs',
      event_value: undefined,
      event_non_interaction: false,
      eventCallback: callback,
      eventTimeout: ANALYTICS_TIMEOUT
    })
    setTimeout(callback, ANALYTICS_TIMEOUT + 200)
  },

  saveSearch: eventCategory => {
    validate({
      params: { eventCategory },
      schema: yup.object({ ...requiredString('eventCategory') })
    })

    analytics.pushEvent('custom_event', {
      event_action: 'save_item',
      event_category: eventCategory,
      event_label: 'save',
      event_non_interaction: false,
      event_value: undefined
    })
  },

  deleteSearch: eventCategory => {
    validate({
      params: { eventCategory },
      schema: yup.object({ ...requiredString('eventCategory') })
    })

    analytics.pushEvent('custom_event', {
      event_action: 'save_item',
      event_category: eventCategory,
      event_label: 'unsave',
      event_non_interaction: false,
      event_value: undefined
    })
  },

  searchSave: ({ eventCategory, eventLabel }) => {
    validate({
      params: { eventCategory, eventLabel },
      schema: yup.object({
        ...requiredString('eventCategory'),
        ...requiredString('eventLabel')
      })
    })

    analytics.pushEvent('custom_event', {
      event_category: eventCategory,
      event_action: 'save_item',
      event_label: eventLabel,
      event_value: undefined,
      event_non_interaction: false
    })
  }
}

const UserProfile = {
  toggler: ({ eventAction, eventLabel }) => {
    validate({
      params: { eventAction, eventLabel },
      schema: yup.object({
        ...requiredString('eventAction'),
        ...requiredString('eventLabel')
      })
    })

    analytics.pushEvent('custom_event', {
      event_category: 'email_preferences',
      event_action: eventAction,
      event_label: eventLabel,
      event_value: undefined
    })
  },

  userAuth: eventLabel => {
    validate({
      params: { eventLabel },
      schema: yup.object({ ...requiredString('eventLabel') })
    })

    analytics.pushEvent('custom_event', {
      event_category: 'user',
      event_action: 'auth',
      event_label: eventLabel,
      event_value: undefined,
      event_non_interaction: false
    })
  }
}

const AgentPage = {
  contactFormOpen: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'agent',
      event_action: 'contact',
      event_label: 'open',
      event_value: undefined,
      event_non_interaction: false
    })
  }
}

const Common = {
  /**
   *
   * @param {string} eventCategory - Current category types are listing | agent
   * @returns {undefined}
   */
  contactAgent: eventCategory => {
    validate({
      params: { eventCategory },
      schema: yup.object({ ...requiredString('eventCategory') })
    })

    analytics.pushEvent('custom_event', {
      event_category: eventCategory,
      event_action: 'contact',
      event_label: 'submit',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  /**
   *
   * @param {string} eventCategory - Current category type is listing
   * @returns {undefined}
   */
  contactOwner: eventCategory => {
    validate({
      params: { eventCategory },
      schema: yup.object({ ...requiredString('eventCategory') })
    })

    analytics.pushEvent('custom_event', {
      event_category: eventCategory,
      event_action: 'contact_owner',
      event_label: 'submit',
      event_value: undefined,
      event_non_interaction: false
    })
  },

  searchActive: () => {
    analytics.pushEvent('custom_event', {
      event_category: 'search',
      event_action: 'text_search',
      event_label: 'open',
      event_value: undefined,
      event_non_interaction: false
    })
  }
}

export default {
  Hdp,
  Srp,
  UserProfile,
  AgentPage,
  Common
}
